"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OTPValueType = void 0;
var OTPValueType;
(function (OTPValueType) {
    OTPValueType[OTPValueType["NUMERIC"] = 0] = "NUMERIC";
    OTPValueType[OTPValueType["ALPHABETIC"] = 1] = "ALPHABETIC";
    OTPValueType[OTPValueType["ALPHABETIC_LOWER"] = 2] = "ALPHABETIC_LOWER";
    OTPValueType[OTPValueType["ALPHABETIC_UPPER"] = 3] = "ALPHABETIC_UPPER";
    OTPValueType[OTPValueType["ALPHANUMERIC"] = 4] = "ALPHANUMERIC";
    OTPValueType[OTPValueType["ALPHANUMERIC_LOWER"] = 5] = "ALPHANUMERIC_LOWER";
    OTPValueType[OTPValueType["ALPHANUMERIC_UPPER"] = 6] = "ALPHANUMERIC_UPPER";
})(OTPValueType || (exports.OTPValueType = OTPValueType = {}));
