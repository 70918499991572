"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOTPRegexForValueType = getOTPRegexForValueType;
const types_1 = require("./types");
function getOTPRegexForValueType(valueType) {
    switch (valueType) {
        case types_1.OTPValueType.NUMERIC:
            return /[^0-9]/g;
        case types_1.OTPValueType.ALPHABETIC:
            return /[^A-Za-z]/g;
        case types_1.OTPValueType.ALPHABETIC_LOWER:
            return /[^a-z]/g;
        case types_1.OTPValueType.ALPHABETIC_UPPER:
            return /[^A-Z]/g;
        case types_1.OTPValueType.ALPHANUMERIC:
            return /[^A-Za-z0-9]/g;
        case types_1.OTPValueType.ALPHANUMERIC_LOWER:
            return /[^a-z0-9]/g;
        case types_1.OTPValueType.ALPHANUMERIC_UPPER:
            return /[^A-Za-z0-9]/g;
        default:
            throw new Error('Invalid OTP field value type');
    }
}
