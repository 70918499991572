"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OTPField = void 0;
const regex_1 = require("../utils/regex");
const types_1 = require("../utils/types");
class OTPField {
    constructor(config) {
        this.fieldValue = '';
        this.disabled = false;
        if (config.boxCount <= 0) {
            throw new Error('Invalid config box count must be grater than zero.');
        }
        this.config = config;
    }
    skeleton() {
        const field = document.createElement('div');
        field.id = this.id;
        field.className = 'otp-field';
        for (let i = 0; i < this.config.boxCount; i++) {
            field.appendChild(this.getBoxElement(i));
        }
        return field;
    }
    getBoxElement(index) {
        const box = document.createElement('input');
        box.id = this.getBoxId(index);
        box.className = 'otp-box';
        box.type = 'text';
        box.maxLength = 1;
        box.autocomplete = 'off';
        box.setAttribute('data-index', index.toString());
        box.addEventListener('input', this.onBoxInput.bind(this));
        box.addEventListener('keydown', this.onBoxKeyDown.bind(this));
        box.addEventListener('focus', this.onBoxFocus.bind(this));
        box.addEventListener('paste', this.onBoxPaste.bind(this));
        return box;
    }
    get value() {
        return this.fieldValue;
    }
    get isDisabled() {
        return this.disabled;
    }
    get id() {
        return `otp-field-${this.config.namespace}`;
    }
    get element() {
        const elem = document.getElementById(this.id);
        if (elem === null) {
            throw new Error(`Element with ID ${this.id} not found in the DOM.`);
        }
        return elem;
    }
    focus() {
        let focusBoxIndex = this.config.boxCount - 1;
        for (let i = 0; i < this.config.boxCount; i++) {
            if (this.getBoxValue(i) === '') {
                focusBoxIndex = i;
                break;
            }
        }
        this.focusBox(focusBoxIndex);
    }
    disable(disabled) {
        for (let i = 0; i < this.config.boxCount; i++) {
            const box = this.getBoxAtIndex(i);
            box.disabled = disabled;
        }
        this.disabled = disabled;
    }
    clear() {
        for (let i = 0; i < this.config.boxCount; i++) {
            this.setBoxValue(i, '');
        }
        this.fieldValue = '';
        this.focusBox(0);
    }
    destroy() {
        this.element.remove();
    }
    build(parentElement) {
        parentElement.appendChild(this.skeleton());
    }
    getOtpRegex() {
        var _a;
        if (this.config.customRegex) {
            return this.config.customRegex;
        }
        return (0, regex_1.getOTPRegexForValueType)((_a = this.config.valueType) !== null && _a !== void 0 ? _a : types_1.OTPValueType.NUMERIC);
    }
    applyRegex(value) {
        return value.replace(this.getOtpRegex(), '');
    }
    getBoxId(index) {
        return `${this.config.namespace}-box-${index}`;
    }
    getBoxAtIndex(index) {
        const boxId = this.getBoxId(index);
        const box = document.getElementById(boxId);
        if (box === null) {
            throw new Error(`Unable to get box at index ${index}`);
        }
        return box;
    }
    getBoxIndex(box) {
        const dataIndex = box.getAttribute('data-index');
        if (dataIndex) {
            return parseInt(dataIndex, 10);
        }
        throw new Error('Unable to get `data-index` attribute for box');
    }
    updateValue() {
        let concatenatedValue = '';
        for (let i = 0; i < this.config.boxCount; i++) {
            concatenatedValue += this.getBoxValue(i);
        }
        this.fieldValue = concatenatedValue;
    }
    focusBox(index) {
        const box = this.getBoxAtIndex(index);
        box.focus();
    }
    focusNextBox(currentBox) {
        const currentBoxIndex = this.getBoxIndex(currentBox);
        if (currentBoxIndex + 1 < this.config.boxCount) {
            this.focusBox(currentBoxIndex + 1);
        }
    }
    focusPrevBox(currentBox) {
        const currentBoxIndex = this.getBoxIndex(currentBox);
        if (currentBoxIndex - 1 >= 0) {
            this.focusBox(currentBoxIndex - 1);
        }
    }
    setBoxValue(index, value) {
        const box = this.getBoxAtIndex(index);
        box.value = value;
    }
    getBoxValue(index) {
        const box = this.getBoxAtIndex(index);
        return box.value;
    }
    onBoxPaste(e) {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text');
        const pastedValue = this.applyRegex(pastedText);
        const currentBoxIndex = this.getBoxIndex(e.target);
        const maxLength = Math.min(this.config.boxCount - currentBoxIndex, pastedValue.length);
        for (let i = 0; i < maxLength; i++) {
            this.setBoxValue(currentBoxIndex + i, pastedValue[i]);
        }
        if (this.config.onPasteBlur === true ||
            this.config.onPasteBlur === undefined) {
            e.target.blur();
        }
        else {
            this.focusBox(currentBoxIndex + maxLength - 1);
        }
        this.updateValue();
    }
    onBoxFocus(e) {
        if (e.target.value.length === 1) {
            e.target.selectionStart = 0;
            e.target.selectionEnd = 1;
        }
    }
    onBoxKeyDown(e) {
        if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
            e.preventDefault();
            this.focusPrevBox(e.target);
        }
        if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
            e.preventDefault();
            this.focusNextBox(e.target);
        }
        if (e.key === 'Backspace' &&
            (e.target.value === '' ||
                e.target.selectionEnd === 0)) {
            this.focusPrevBox(e.target);
        }
        if (e.key === 'Delete' &&
            (e.target.value === '' ||
                (e.target.selectionStart !== 0 &&
                    e.target.selectionEnd === 1))) {
            this.focusNextBox(e.target);
        }
        if (e.code === 'KeyZ' || e.code === 'KeyY') {
            e.preventDefault();
        }
    }
    onBoxInput(e) {
        const updatedValue = this.applyRegex(e.target.value);
        e.target.value = updatedValue;
        if (e.target.value !== '') {
            this.focusNextBox(e.target);
        }
        this.updateValue();
    }
}
exports.OTPField = OTPField;
